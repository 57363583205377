<template>
    <v-text-field
        :label="label"
        v-model="displayValue"
        disabled
        :prepend-icon="showIcon ? 'mdi-map-marker' : null" />
</template>

<script>
export default {
    name: 'BT-Location',
    props: {
        location: null,
        label: {
            type: String,
            default: 'Location'
        },
        showAddress: {
            type: Boolean,
            default: true
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        includeName: {
            type: Boolean,
            default: false
        },
        includeCompany: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        displayValue() {
            if (this.location) {
            if (this.showAddress) {
                var rStr = '';
                
                if (this.includeCompany && this.location.companyAccount != null) {
                    rStr = this.location.companyAccount.companyName + ' | ';
                }

                if (this.includeName && this.location.locationName != null) {
                    rStr = this.location.locationName + ' - ';
                }
                if (this.location.addressLineOne) {
                    rStr = rStr + this.location.addressLineOne + ' '
                }
                if (this.location.streetNumber) {
                    rStr = rStr + this.location.streetNumber + ' ';
                }
                if (this.location.streetName) {
                    rStr = rStr + this.location.streetName + ', ';
                }
                if (this.location.suburb) {
                    rStr = rStr + this.location.suburb + ' ';
                }
                if (this.location.state) {
                    rStr = rStr + this.location.state + ' ';
                }
                if (this.location.postcode) {
                    rStr = rStr + this.location.postcode;
                }
                return rStr;
            }
            else {
                return this.location ? this.location.locationName : "No Location";
            }
            }
            return null;
        }
    }
}
</script>